import { useState } from 'react'
import './App.css'

function Info() {
  return (
      <>
      <div className="info">
         <h1><center>INFO</center></h1>
         <p>This is my first React program, offcourse after the customery 'Hello World'.</p>
         <p>As learning React was my main objective I have used most of the key features of React -</p>
         <ul>
          <li>state</li>
          <li>props</li>
          <li>conditional rendering</li>
          <li>partial rendering</li>
          <li>event handling etc.</li>
         </ul>
         <br></br>
         <p>This is a standard Tic Tac Toe game with a slight twist</p>
         <p>Its a 4X4 grid and 4 continuous X or O in vertical, horizontal or diagonal line wins</p>
      </div>
      </>
    )
}

function App() {
  const [val, setVal] = useState(['-','-','-','-','-','-','-','-','-','-','-','-','-','-','-','-','-'])
  const [curVal, setCurVal] = useState("X")

  function setBoard(e) {
//    console.log(curVal)
    if (curVal == 'X') {setCurVal('O'); } //console.log("curval set to O")}
    else {setCurVal('X'); } //console.log("curval set to X")}

    if (checkWinner() == "X") {
      alert("X is winner");
    }

    function setVal(id) {
//      console.log(id);
      val[id] = curVal;

    }

    const id = Number(e.target.id.substring(1,3))
    setVal(id)
//    console.log(e.target.id, id)
//    console.log("event triggered by " + e.target.id)

    document.getElementById(e.target.id).disabled = true;

    function checkWinner() {
      let str = val.join('') + val[1] + val[5] + val[9] + val[13] + val[2] + val[6] + val[10] + val[14] + 
                val[3] + val[7] + val[11] + val[15] + val[4] + val[8] + val[12] + val[16] +
                val[1] + val[6] + val[11] + val[16] + val[4] + val[7] + val[10] + val[13]
//      alert(str)

      if (str.search("XXXX") > 0) {return "X"}
      if (str.search("OOOO") > 0) {return "O"}
      
    }
  }

  
  return (
    <>
      <div className="page">
        <div className="leftP"></div>
        <div className="board">
          <div><h1>Tic Tac Four</h1></div>
          <div className="cells">
            <button id="v01" onClick={setBoard}>{val[1]}</button>
            <button id="v02" onClick={setBoard}>{val[2]}</button>
            <button id="v03" onClick={setBoard}>{val[3]}</button>
            <button id="v04" onClick={setBoard}>{val[4]}</button>

            <button id="v05" onClick={setBoard}>{val[5]}</button>
            <button id="v06" onClick={setBoard}>{val[6]}</button>
            <button id="v07" onClick={setBoard}>{val[7]}</button>
            <button id="v08" onClick={setBoard}>{val[8]}</button>

            <button id="v09" onClick={setBoard}>{val[9]}</button>
            <button id="v10" onClick={setBoard}>{val[10]}</button>
            <button id="v11" onClick={setBoard}>{val[11]}</button>
            <button id="v12" onClick={setBoard}>{val[12]}</button>

            <button id="v13" onClick={setBoard}>{val[13]}</button>
            <button id="v14" onClick={setBoard}>{val[14]}</button>
            <button id="v15" onClick={setBoard}>{val[15]}</button>
            <button id="v16" onClick={setBoard}>{val[16]}</button>
          </div>
          <div className="winner"></div>
        </div>
        <Info />
      </div>
    </>
  )
}

export default App
